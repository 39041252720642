

import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import Component from 'vue-class-component'
import Input from '~/components/ui/Input.vue'
import InputDate from '~/components/ui/InputDate.vue'
import CheckoutError from '~/components/checkout/checkoutError.vue'
import PopupInn from '~/components/popup/popupInn.vue'
import OpenPdf from '~/components/ui/openPdf.vue'
import ButtonLoader from '~/components/ui/buttonLoader.vue'
import checkAccrualsMixin from '~/mixins/checkAccrualsMixin'
@Component({
  components: {OpenPdf, PopupInn, CheckoutError, InputDate, Input, ButtonLoader},
  mixins: [checkAccrualsMixin]
  })
// @ts-ignore
export default class checkoutComp extends SearchFormAbstract {
  getType (): string {
    console.log('get tyoe call')
    return 'fns'
  }
}
