<template>
    <div class="faq-question center">
        <div itemprop="mainEntity" itemscope itemtype="http://schema.org/Question" class="faq-question_item">
            <input class="faq-question_item__checkbox" type="checkbox" :id="checkboxId">

            <label class="faq-question_item_label" :for="checkboxId">
                <div class="faq-question_item_label_heading">

                    <svg class="faq-question_item_label_heading__arrow" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.69385 6.35596L15.3063 12L9.69385 17.644" stroke="#0E1211" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <component :is="tag" itemprop="name" class="faq-question_item_label_heading__title">
                        {{ text }}
                    </component>

                </div>
            </label>

            <div itemscope itemprop="acceptedAnswer" itemtype="http://schema.org/Answer" class="faq-question_item__text">
                <slot itemprop="text" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    checkboxId: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    tag: {
        type: String,
        default: 'h3'
    }
  }
}
</script>

<style lang="scss" scoped>
    .faq-question {
        width: 100%;

        &_item {
            display: flex;
            flex-direction: column;

            background-color: var(--background-color);

            padding: 20px;
            border: 1px solid var(--border-color);
            border-radius: 13px;

            width: 100%;
            max-width: 1296px;

            &__checkbox {
                display: none;
            }

            &_label {
                cursor: pointer;
                display: flex;
                align-items: center;

                &_heading {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    &__arrow {
                        path {
                            min-width: 24px;
                            min-height: 24px;
                        }
                    }

                    &__title {
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 28px;
                        color: #0E1211; //тёмный
                    }
                }
            }

            &__text {
                display: none;
                margin-top: 15px;
                font-size: 18px;
                line-height: 27px;
                color: var(--bright-text-color);

                ol {
                    li {
                        text-indent: -24px;
                        padding-left: 24px;
                    }
                }
            }

            &__checkbox:checked + &_label  {
                .faq-question_item_label_heading {
                    &__arrow {
                        transform: rotate(90deg);

                        path {
                            stroke: var(--primary-color);
                        }
                    }

                    &__title {
                        color: var(--primary-color);
                    }
                }
            }

            &__checkbox:checked~&__text  {
                display: block;
            }
        }
    }

    @media (max-width: 767px) {
        .faq-question {
            width: 100%;

            &_item {
                max-width: 390px;
                padding: 20px;

                &_label {
                    &_heading {
                        gap: 15px;

                        &__arrow {
                            min-width: 24px;
                            min-height: 24px;
                        }

                        &__title {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }

                &__text {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

        }
    }
</style>
