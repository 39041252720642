<template>
    <div class="guarantees center">
        <div class="guarantees_box" :class="{ 'guarantees_box_column': secondVariant }">
            <div class="guarantees_box__item">
                <div class="guarantees_box__item__icon">
                    <img class="guarantees_box__item__icon__image" src="@/assets/images/like.png" alt="like_icon">
                </div>

                <div class="guarantees_box__item_description">
                    <h3 class="guarantees_box__item_description__title">Гарантия погашения</h3>
                    <p class="guarantees_box__item_description__text">Сведения об оплате автоматически передаются в базу данных Федеральной налоговой службы</p>
                </div>
            </div>

            <div class="guarantees_box__item">
                <div class="guarantees_box__item__icon">
                    <img class="guarantees_box__item__icon__image" src="@/assets/images/data.png" alt="data_icon">
                </div>

                <div class="guarantees_box__item_description">
                    <h3 class="guarantees_box__item_description__title">Официальные данные</h3>
                    <p class="guarantees_box__item_description__text">Поиск задолженностей проводится в базе данных Федеральной налоговой службы</p>
                </div>
            </div>

            <div class="guarantees_box__item">
                <div class="guarantees_box__item__icon">
                    <img class="guarantees_box__item__icon__image" src="@/assets/images/wallet.png" alt="wallet_icon">
                </div>

                <div class="guarantees_box__item_description">
                    <h3 class="guarantees_box__item_description__title">Мгновенная оплата</h3>
                    <p class="guarantees_box__item_description__text">Квитанция об оплате бесплатно отправляется на вашу электронную почту сразу после оплаты</p>
                </div>
            </div>

            <div class="guarantees_box__item">
                <div class="guarantees_box__item__icon">
                    <img class="guarantees_box__item__icon__image" src="@/assets/images/safe.png" alt="safe_icon">
                </div>

                <div class="guarantees_box__item_description">
                    <h3 class="guarantees_box__item_description__title">Безопасные платежи</h3>
                    <p class="guarantees_box__item_description__text">Безопасность обеспечива-ется сквозным TLS шифро-ванием и подтверждена сертификатом PCI DSS</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    secondVariant: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
    .guarantees {
        margin-bottom: 32px;

        &_box {
            display: flex;
            gap: 32px;
            max-width: 1296px;

            &__item {
                display: flex;
                align-items: center;
                gap: 15px;
                height: 100%;
                width: 100%;
                min-height: 143px;
                max-width: 300px;
                padding: 24px 19px;
                border: 1px solid #ECF1FE;
                border-radius: 13px;

                &__icon {
                    &__image {
                        width: 45px;
                        height: 95px;
                    }
                }

                &_description {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    &__title {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 21.6px;
                        color: var(--title-text-color);
                    }

                    &__text {
                        font-size: 14px;
                        line-height: 16.8px;
                        color: var(--bright-text-color);
                    }
                }
            }
        }
    }

    .guarantees_box_column {
        flex-direction: column;
        gap: 15px;
    }

    @media (max-width: 767px) {
        .guarantees {
            margin-bottom: 25px;

            &_box {
                display: flex;
                flex-direction: column;
                gap: 15px;
                max-width: 390px;

                &__item {
                    gap: 31px;
                    min-height: 135px;
                    max-width: 390px;
                    padding: 16px 32px;

                    &_description {
                        &__title {
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 21.6px;
                        }

                        &__text {
                            font-size: 16px;
                            line-height: 19.2px;
                        }
                    }
                }
            }
        }
    }
</style>
